@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.statistic {
  &-datepicker {
    width: 149px;
    height: 41px;
    border-radius: 8px;
    background: #F2F3F3 url("../../assets/img/datepicker-icon.svg") no-repeat right 10px center;
    border-width: 0;
    color: #818B88;
    padding: 0 10px;
    -webkit-appearance: none;
    @include responsive-below($grid-md){
      width: 100%;
    }
  }
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-date-block {
    display: flex;
    padding-bottom: 25px;
  }
  &-header {
    display: flex;
    padding: 0 10px;

    div * {
      font-weight: 700;
      color: #000000;
    }
  }
  &-date-container {
    display: flex;
    align-items: center;
    @include responsive-below($grid-md){
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &-show-period-label {
    padding: 25px 10px 10px 10px;
    font-weight: 500;
    @include responsive-below($grid-md){
      flex: 0 0 100%;
      padding: 0 0 15px;
    }
  }

  &-date-element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;

    @include responsive-below($grid-md){
      flex: 0 0 calc(50% - 10px);
      margin-right: 0;
    }
  }

  &-company-name {
    padding-right: 10px;
    flex: 0 0 35%;
    font-size: 14px;
    word-break: break-word;
    @include responsive-below($grid-md){
      flex-basis: 100%;
      margin-bottom: 10px;
    }
  }

  &-company-number {
    flex-shrink: 0;
    word-break: break-word;
    width: 35px;
  }

  &-fullname {
    flex: 0 0 25%;
    padding-right: 10px;
    @include responsive-below($grid-md){
      flex-basis: 33.333%;
    }
  }
  &-last-enter {
    flex: 0 0 20%;
    padding-right: 10px;
    @include responsive-below($grid-md){
      flex-basis: 33.333%;
    }
  }
  &-counts {
    flex: 0 0 20%;
    @include responsive-below($grid-md){
      flex-basis: 33.333%;
    }
  }
  &-list {
    margin: 10px 0;
    padding: 0;
  }
  &-item {
    list-style: none;
    display: flex;
    padding: 12px;
    @include responsive-below($grid-md){
      flex-wrap: wrap;
    }
  }
  &-item:nth-child(odd) {
    background: #F2F3F3;
  }

  &-list-total {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    list-style: none;
    padding: 0;

    @include responsive-below($grid-md){
      align-items: flex-start;
    }

    li {
      margin-bottom: 16px;
      font-weight: 500;

      @include responsive-below($grid-md){
        display: flex;
        width: 100%;
      }

      span {
        width: 100px;
        display: inline-block;
        margin-left: 64px;

        @include responsive-below($grid-md){
          margin-left: auto;
          margin-right: 0;
          text-align: right;
          width: auto;
        }
      }
    }
  }

  &-list-download {
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: flex-end;

    @include responsive-below($grid-md){
      align-items: center;
      margin-bottom: 32px;
    }
  }

  &-download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(198.97% 116.52% at 42.5% 92.5%, #449F4F 0%, #4EBE60 100%);
    text-align: center;
    color: #fff;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    min-width: 200px;

    .loader-wrapper.small .loader {
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      &:after {
        width: 15px;
        height: 15px;
        border-color: #ffffff30 transparent #ffffff30 transparent;
      }
    }

    &.btn {
      border-radius: 4px;
      padding: 8px 24px;

      @include responsive-below($grid-md){
        width: 100%;
      }
    }
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(0.75);
}