@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.users {
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include responsive-below($grid-md){
      .btn-outline {
        width: 100%;
      }
    }
  }

  &-header {
    margin-top: 20px;
    display: flex;
    padding: 0 10px;
  }

  &-name {
    flex: 0 0 30%;

    @include responsive-below($grid-md){
      font-size: 18px;
      font-weight: 600;
      flex-basis: auto;
      margin-bottom: 8px;
    }
  }

  &-phone {
    flex: 0 0 30%;

    @include responsive-below($grid-md){
      flex-basis: 100%;
      order: 1;
    }
  }

  &-status {
    flex: 0 0 calc(40% - 100px);

    &.status-plus:after {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-left: 5px;
      background: url("/assets/img/plus.svg") center/cover no-repeat;
      vertical-align: bottom;
    }
  }

  &-controls {
    flex: 0 0 100px;
    text-align: right;

    @include responsive-below($grid-md){
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    &-btn {
      color: $icon-color;
      background: none;
      border: 0;
      width: 30px;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;

      @include responsive-below($grid-sm){
        font-size: 20px;
      }

      &.-edit:hover {
        color: $brand;
      }

      &.-remove:hover {
        color: $danger;
      }
    }
  }

  &-list {
    margin: 10px 0;
    padding: 0;

  }

  &-item {
    list-style: none;
    display: flex;
    padding: 12px;

    @include responsive-below($grid-md){
      flex-wrap: wrap;
      position: relative;
      padding: 16px 70px 16px 12px;
      border-bottom: 1px solid $gray;

      &:last-child {
        border-bottom: 0;
      }

      .users-status {
        font-size: 0;
        flex-basis: auto;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }
    }

    &:nth-child(odd){
      background: $gray;

      @include responsive-below($grid-md){
        background: none;
      }
    }
  }
}
