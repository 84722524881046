@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.device {
  &-list {
    margin: 20px -12px 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @include responsive-below($grid-sm){
      margin: 20px 0 0;
    }
  }

  &-item {
    border: 1px solid $gray;
    border-radius: 8px;
    flex: 0 0 calc(50% - 24px);
    margin: 12px;

    @include responsive-below($grid-sm){
      margin: 12px 0;
      flex: 0 0 100%;
    }
  }

  &-header {
    border-radius: 8px;
    background: $gray;
    padding: 12px 60px 12px 12px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: rgba(129, 139, 136, 0.15);
    }

    &:after {
      content: "";
      position: absolute;
      right: 15px;
      width: 8px;
      height: 8px;
      transform: rotate(-45deg);
      border: solid 1px $icon-color;
      border-top: 0;
      border-right: 0;
      bottom: 12px;
      transition: all 0.4s;
    }

    &.expanding:after,
    &.expanded:after {
      transform: rotate(135deg);
    }
  }

  &-content {
    padding: 12px;
    color: $gray-dark;
  }

  &-ip {
    margin-bottom: 4px;
    font-size: 18px;
  }

  &-platform {
    margin-bottom: 5px;
  }

  &-icon {
    margin-right: 8px;
    font-size: 20px;
    @include gradient-color();

    &.icon-undefined {
      margin-right: 0;
    }
  }

  &-date {
    position: absolute;
    right: 12px;
    top: 12px;
  }

}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $brand;
  margin-right: 8px;
}
