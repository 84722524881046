@import "../../assets/styles/base";

.search {
  &-wrapper {
    width: 200px;
    @include responsive-below($grid-md){
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      transform: translateX(110%);

      &.search-open {
        transform: none;
        z-index: 2;
      }
    }
  }

  &-mobile {
    @include responsive-below($grid-md) {
      background: #fff;
      transition: all 0.2s;
    }
  }

  &-block {
    background: $gray;
    border-radius: 8px;
    padding: 10px;
  }

  &-input {
    font-size: 14px;
    border: 0;
    background: transparent;
    width: calc(100% - 20px);

    &:focus {
      outline: none;
    }
  }

  &-btn {
    border: 0;
    background: none;
    color: $icon-color;
    cursor: pointer;

    @include responsive-below($grid-md){
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20px;
      padding: 0;
      margin: 0;
    }
  }

  &-content {
    @include responsive-below($grid-md){
      padding: 15px;
    }
  }

  &-label {
    font-size: 12px;
    line-height: 1.5;
  }
}

.header-mobile {
  height: 50px;
  border-bottom: solid 1px #F2F3F3;
  text-align: center;
  padding: 15px;
  position: relative;

  &-icon {
    position: absolute;
    left: 15px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border: solid 1px $icon-color;
    border-top: 0;
    border-right: 0;
    top: calc(50% - 5px);
  }
}

.mobile.search-btn .icon-search {
  font-size: 20px;
  color: $icon-color;
}