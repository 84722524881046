@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.terms-list {
  padding-left: 0;

  ul {
    margin-bottom: 10px;

    li {
      margin-bottom: 5px;
    }
  }

  & > li > ul {
    padding-left: 15px;
    padding-top: 5px;

    & > li {
      padding-left: 25px;
      text-indent: -25px;
    }

    & > li > ul {
      padding-left: 0;
      padding-top: 5px;

      & > li {
        padding-left: 38px;
        text-indent: -38px;
      }

    }
  }

  li {
    list-style: none;
  }
}
