@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px 0;
  font-size: 12px;
  color: $gray-dark;

  @include responsive-below($grid-md){
    text-align: center;

    .flex {
      flex-direction: column;
    }

    .aside {
      width: 100%;
    }
  }

  a {
    text-decoration: none;
    color: $gray-dark;

    &:hover {
      text-decoration: underline;
    }
  }

  &-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include responsive-below($grid-md){
      order: -1;
      margin-bottom: 5px;
    }
  }
}
