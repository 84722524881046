//== Media Query - Responsive Below
@mixin responsive-below($width) {
  	@media (max-width: #{$width}) {
    	@content;
  	}
}

//== Media Query - Responsive Above
@mixin responsive-above($width) {
  	@media (min-width: #{$width}) {
    	@content;
  	}
}

@mixin gradient-color {
   background: $brand-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
