@import "../../assets/styles/base";

@include responsive-below($grid-sm){
  .mobile-catalog {
    &-image {
      background: $gray;
      position: relative;
      height: 70vh;

      &:before {
        content: "";
        display: block;
        z-index: -1;
        background: $gray;
        position: absolute;
        left: -15px;
        top: -15px;
        width: calc(100% + 30px);
        height: calc(100% + 30px);
      }
    }

    &-block {
      position: fixed;
      z-index: 1;
      width: 100%;
      height: 30vh;
      bottom: 0;
      left: 0;
      background: #fff;
      border-radius: 16px 16px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}