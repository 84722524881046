$grid-xs: 374px;
$grid-sm: 576px;
$grid-md: 767px;
$grid-lg: 992px;
$grid-xl: 1200px;

$link-phone-color: #1A5EC6;
$dark: #021610;
$gray: #F2F3F3;
$gray-dark: #4E5D58;
$brand: #4AB158;
$brand-gradient: radial-gradient(198.97% 116.52% at 42.5% 92.5%, #449F4F 0%, #4EBE60 100%);
$icon-color: #818B88;
$danger: #C01717;
$success: #168A67;
$status: #C29B10;