@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.notification-form {
  margin-top: 16px;
}
.choose-container {
  border: solid 1px green;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding-top: 6px !important;
}

.input-phone-mask-wrapper {
  position: relative;
  margin-right: 5px;

  .drop-down-panel {
    position: absolute;
    top: 38px;
    width: 100%;
    max-height: 140px;
    background: white;
    z-index: 1;
    overflow-y: auto;
    border: solid 1px lightgray;
    border-radius: 6px;
    box-shadow: 0 0 4px #d3d3d373;
  }
}

.choose-item {
  margin-right: 5px;
  display: flex;
  padding: 7px 4px;
  border-radius: 6px;
  font-size: 12px;
  margin-top: 6px;
  background: #48aa5636;
  color: #090909;
  position: relative;
  width: 150px;
}

.close-button {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 2px;
  top: 5px;
  opacity: 1;
  background: #8080801c;
  padding: 2px;
  border-radius: 100px;
  cursor: pointer;

  .close-button-icon {
    width: 100%;
    height: 100%;
    background: url("/assets/img/close.svg") center/cover no-repeat;
  }
}

.choose-input-mask {
  border: none;
  display: flex;
  padding: 7px 4px;
  padding-right: 22px;
  border-radius: 6px;
  font-size: 12px;
  margin-top: 6px;
  background: none;
}

.phone-choose-item {
  display: flex;
  padding: 7px 4px;
  font-size: 11px;
  border-bottom: 1px solid #d3d3d373;
  color: #090909;
  cursor: pointer;
  position: relative;
  width: 100%;

  &:hover {
    background: #48aa5636;
  }
}

.checkbox-input {
  cursor: pointer;
}

.checkbox-label {
  margin-bottom: 0 !important;
  cursor: pointer;
}

.message-content {
  display: flex;
  min-height: 80px;
  justify-content: center;
  align-items: center;
  margin-bottom: 0 !important;
}
