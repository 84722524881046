@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.tabs {
  &-wrapper {
    border-bottom: solid 1px $gray;
    width: 100%;

    @include responsive-below($grid-md) {
      margin-bottom: 15px;
    }
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;

    @include responsive-below($grid-sm) {
      &-justify {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &-item {
    margin-right: 20px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }

  &-link {
    color: $gray-dark;
    padding: 10px 0;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;

    @include responsive-below($grid-md) {
      font-size: 18px;
    }

    &:hover {
      color: $brand;
    }

    &.active {
      font-weight: 500;
      color: $dark;
      position: relative;

      &:before {
        content: "";
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        background: radial-gradient(198.97% 116.52% at 42.5% 92.5%, #449F4F 0%, #4EBE60 100%);
        border-radius: 4px 4px 0 0;
      }
    }
  }
}
