@import "./variables";
@import "./mixins";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

.container {
  max-width: 1055px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

.text-bolder {
  font-weight: 500;
}

.text-dark {
  color: $dark;
}

.aside {
  @include responsive-below($grid-md) {
    width: 100%;
    flex-basis: 100%;
    margin-right: 0;
    position: fixed;
    top: 34px;
    left: 0;
    right: 0;
    bottom: 0;

    &.menu-open {
      z-index: 100;
    }
  }

  &-block {
    max-width: 200px;
    margin-right: 24px;
    flex: 0 0 200px;

    @include responsive-below($grid-md) {
      flex-basis: auto;
      margin-right: 0;
      max-width: 100%;
    }
  }
}

.flex {
  display: flex;
}

.link {
  cursor: pointer;
}

.link-phone {
  font-size: 14px;
  color: $link-phone-color;
  text-decoration: none;
  transition: all 0.3s;
}

a.link-phone {
  cursor: pointer;
}

a.link-phone:hover {
  text-decoration: underline;
}

.react-responsive-modal-closeButton:focus {
  outline: none;
}

.btn {
  display: inline-block;
  background: radial-gradient(198.97% 116.52% at 42.5% 92.5%, #449F4F 0%, #4EBE60 100%);
  border-radius: 8px;
  padding: 12px 35px;
  text-align: center;
  color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;

  @include responsive-below($grid-sm){
    padding-left: 30px;
    padding-right: 30px;
  }

  @include responsive-below($grid-xs){
    padding-left: 25px;
    padding-right: 25px;
  }

  & + & {
    margin-left: 8px;
  }

  &:hover:not(.btn-disabled) {
    background: radial-gradient(198.97% 116.52% at 42.5% 92.5%, #298334 0%, #2BA53E 100%);
  }

  &:focus {
    outline: none;
  }

  &.-full {
    width: 100%;
    padding: 12px 15px;
  }

  &.btn-outline {
    color: $brand;
    background: none;
    border: 1px solid $brand;

    &:hover {
      color: #fff;
      background: radial-gradient(198.97% 116.52% at 42.5% 92.5%, #298334 0%, #2BA53E 100%);
    }
  }

  &.btn-add:before {
    content: "+";
    margin-right: 5px;
    font-size: 1.5em;
    line-height: 14px;
    vertical-align: -2px;
  }

  &.btn-simple {
    background: $gray;
    color: $gray-dark;

    &-success {
      color: $brand;
    }
  }

  &.btn-danger {
    background: $danger;
  }

  &.btn-disabled {
    opacity: 0.5;
  }
}

.error {
  color: $danger;
  text-align: left;
  padding: 5px 0;

  &-info {
    color: $dark;
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.mobile {
  @include responsive-above($grid-md) {
    display: none !important;
  }

  &-title {
    font-size: 18px;
  }
}

.desktop {
  @include responsive-below($grid-md) {
    display: none !important;
  }
}

.content {
  width: 100%;

  @include responsive-below($grid-md) {
    position: relative;
    padding-top: 64px;
  }
}

.App {
  padding-bottom: 40px;
  position: relative;
  min-height: 100vh;

  @include responsive-below($grid-md) {
    padding-bottom: 50px;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-big {
  font-size: 18px;
}

.w-50 {
  width: 50%;
}

.-gray-dark {
  color: $gray-dark;
}

.modal {

  &-overlay {
    background: rgba($dark, 0.5);
  }

  &-default {
    width: 440px;
    max-width: calc(100% - 40px);
    border-radius: 16px;
    padding: 25px;
  }

  &-wide {
    width: 500px;
  }

  &-close {
    color: $icon-color;
    font-size: 11px;

    &:hover {
      color: $danger;
    }
  }

  &-title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;

    &.-sub {
      font-weight: 400;
    }
  }

  &-group {
    margin-bottom: 25px;

    &.-big {
      margin-bottom: 35px;
    }
  }
}

.form {
  &-group {
    margin-bottom: 15px;
  }

  &-label {
    display: block;
    text-align: left;
    color: $dark;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 5px;
  }

  &-control {
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    background: $gray;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    border: 0;

    &::placeholder {
      color: $icon-color;
    }
  }
}

.pagination {
  list-style: none;
  margin: 40px 0 0;
  margin: 40px 0 0;
  padding: 0;
  text-align: right;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  .active {
    font-size: 18px;
    color: $brand;
    font-weight: 700;
  }

  .previous:not(.disabled),
  .next:not(.disabled) {
    color: $brand;
  }

  .page-link {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      color: $brand;
    }
  }

  li:not(.disabled) a {
    cursor: pointer;
  }
}

.flex-between {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.switch {
  display: inline-block;
  position: relative;
  background: rgba($gray-dark, 0.5);
  border-radius: 32px;
  width: 32px;
  height: 16px;
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
    left: -100%;

    & + span {
      display: inline-block;
      background: $icon-color;
      width: 24px;
      height: 24px;
      border-radius: 38px;
      position: absolute;
      top: calc(-50% + 4px);
      left: calc(-50% + 10px);
      transition: all 0.3s;
    }

    &:checked + span {
      background: radial-gradient(198.97% 116.52% at 42.5% 92.5%, #449F4F 0%, #4EBE60 100%);
      left: 50%;
    }
  }
}

.page-title {
  padding: 10px 0;
}

.textarea-only-vertical {
  resize: vertical;
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?hxqi6m');
  src: url('../fonts/icomoon.eot?hxqi6m#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?hxqi6m') format('truetype'),
  url('../fonts/icomoon.woff?hxqi6m') format('woff'),
  url('../fonts/icomoon.svg?hxqi6m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cog:before {
  content: "\e911";
}

.icon-os.x:before {
  content: "\e90f";
}

.icon-linux:before {
  content: "\e910";
}

.icon-androidos:before {
  content: "\e90a";
}

.icon-ios:before {
  content: "\e90b";
}

.icon-windows:before {
  content: "\e90c";
}

.icon-remove:before {
  content: "\e90d";
}

.icon-edit:before {
  content: "\e90e";
}

.icon-copy:before {
  content: "\e909";
}

.icon-order:before {
  content: "\e908";
}

.icon-times:before {
  content: "\e900";
}

.icon-bar:before {
  content: "\e901";
}

.icon-search:before {
  content: "\e902";
}

.icon-download:before {
  content: "\e903";
}

.icon-bell:before {
  content: "\e904";
}

.icon-user:before {
  content: "\e905";
}

.icon-users:before {
  content: "\e906";
}

.icon-layers:before {
  content: "\e907";
}

.icon-stats-dots:before{
  content:"\e912";
}

.icon-info:before {
  content: "\e913";
}
.icon-create-notification:before {
  content: "\e90e";
}
