@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.info {
  &-list {
    list-style: none;
    padding: 0;
    margin: 15px 0;

    li {
      margin-bottom: 15px;
    }
  }
}