@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.order {
  &s {
    margin-top: 10px;
  }

  &-item {
    font-size: 12px;

    @include responsive-below($grid-md){
      background: transparent;
      margin-bottom: 8px;
    }

    &:nth-child(even){
      background: $gray;

      @include responsive-below($grid-md){
        background: transparent;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
    position: relative;

    @include responsive-below($grid-md){
      flex-wrap: wrap;
      text-align: center;
      border-radius: 8px;
      background: $gray;
      padding: 10px 0;
    }

    &.-list {
      @include responsive-below($grid-md){
        display: none;
      }
    }

    &:not(.-list) {
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        right: 10px;
        width: 8px;
        height: 8px;
        transform: rotate(-45deg);
        border: solid 1px $icon-color;
        border-top: 0;
        border-right: 0;
        top: calc(50% - 6px);
        transition: all 0.4s;

        @include responsive-below($grid-md){
          display: none;
        }
      }
      .order-code {
        &:before {
          content: "\e903";
          font-family: "Icomoon";
          color: $brand;
          margin-right: 5px;
        }
      }
    }

    &.expanding:after,
    &.expanded:after {
      transform: rotate(135deg);
    }
  }

  &-name {
    flex: 0 0 20%;
    font-size: 14px;
    padding: 10px;
    word-break: break-all;
  }

  &-code {
    flex: 0 0 24%;
    font-size: 14px;
    padding: 10px;
    word-break: break-all;

    @include responsive-below($grid-md){
      flex-basis: 100%;
      font-size: 18px;
    }

    &-mobile {
      @include responsive-below($grid-md){
        font-weight: 600;
        font-size: 18px;
        padding: 12px 15px 12px 40px;
        background: $gray;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 15px;

        &:before {
          content: '\e903';
          font-family: "Icomoon";
          color: $brand;
          position: absolute;
          left: 15px;
        }
      }
    }
  }

  &-mobile-name {
    align-self: flex-start;
    display: flex;
    margin-left: 10px;
    margin-bottom: 5px;
    color: gray;
  }

  &-status {
    flex: 0 0 15%;
    color: $status;
    padding: 10px;

    @include responsive-below($grid-md){
      flex-basis: 100%;
    }

    &.processing {
      .status-bar-progress,
      .status-dot.active-true {
        //background: red;
      }
    }

    &.manufacturing {
      .status-bar-progress,
      .status-dot.active-true {
        //background: red;
      }
    }

    &.shipping {
      .status-bar-progress,
      .status-dot.active-true {
        //background: red;
      }
    }

    &.done {
      .status-bar-progress,
      .status-dot.active-true {
        background: $brand;
      }
    }
  }

  &-name {
    flex: 0 0 20%;

    @include responsive-below($grid-md){
      display: none;
    }
  }

  &-payment {
    flex: 0 0 15%;
    padding: 10px;

    @include responsive-below($grid-md){
      flex-basis: 50%;
      border-right: solid 1px $gray-dark;
    }

    &.-success {
      color: $success;
    }

    &.-danger {
      color: $danger;
    }
  }

  &-delivery {
    flex: 0 0 15%;
    padding: 10px;

    @include responsive-below($grid-md){
      flex-basis: 50%;
    }
  }

  &-date {
    flex: 0 0 10%;
    padding: 10px;

    @include responsive-below($grid-md){
      display: none;
    }
  }

  &-content {
    padding: 0 10px;

    @include responsive-below($grid-md){
      padding: 100px 15px 60px 15px;
      width: 100%;
      height: 100vh;
      overflow: auto;
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      background: #fff;
      overflow: auto !important;
    }

    &-mobile {
      &-header {
        position: fixed;
        background: #fff;
        width: 100%;
        padding: 15px 40px;
        text-align: center;
        margin-bottom: 50px;
        top: 0;
        left: 0;
        z-index: 1;
      }

      &-close {
        width: 40px;
        position: absolute;
        right: 0;
        top: 18px;
        text-align: center;
      }
    }

    &-item {
      margin-bottom: 15px;
    }
    &-progress {
      width: 50%;
      order: 2;

      @include responsive-below($grid-md){
        float: none;
        width: calc(100% + 30px);
        background: $gray;
        padding: 10px 15px;
        margin: 0 -15px 15px;
      }

      &-item {
        margin-bottom: 15px;
        justify-content: space-between;
        position: relative;
        padding-left: 28px;


        &-icon {
          content: "";
          display: inline-block;
          background: #D1D5D4;
          border: 1px solid #D1D5D4;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 2px;
          width: 10px;
          height: 10px;
        }

        &:nth-child(even):not(.-large) .order-content-progress-item-icon {
          width: 6px;
          height: 6px;
          left: 2px;
          top: 4px;
        }

        .label {
          opacity: 0.3;
        }

        .active {
          color: $status;
        }

        .darked {
          color: $dark;
          opacity: 0.5;
        }

        &:after {
          content: "";
          display: inline-block;
          background: rgba($icon-color, 0.3);
          position: absolute;
          left: 4px;
          top: 100%;
          width: 2px;
          height: 13px;
        }

        &:last-child:after {
          display: none;
        }

        &-dot {
          display: inline-block;
          margin-left: 4px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: $icon-color;

          &.active {
            background: $status;
          }
        }
      }
    }

    &-sum {
      width: 50%;
      padding-right: 15px;

      @include responsive-below($grid-md){
        width: 100%;
        order: 2;
      }
    }

    &-pay {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      order: 3;

      @include responsive-below($grid-md){
        margin-top: 15px;
        width: 100%;

        @include responsive-below($grid-sm){
          flex-direction: column-reverse;
          margin-bottom: 20px;
        }
      }

      .label {
        padding-right: 10px;
      }

      & > div {
        flex: 0 0 50%;

        @include responsive-below($grid-sm){
          width: 100%;
          text-align: center;
          margin: 5px 0;
        }
      }
    }

    &-delivery {
      margin-top: 30px;

      @include responsive-below($grid-md){
        margin-top: 0;
      }

      &-block {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        @include responsive-below($grid-sm){
          display: block;
        }

        &-content {
          @include responsive-below($grid-md){
            width: calc(100% + 30px);
            margin: 0 -15px;
            padding: 15px;
          }

          @include responsive-below($grid-sm){
            background: $gray;
          }
        }
      }

      &-manager {
        width: 50%;
        padding-top: 30px;
      }
    }
  }

  &-detail-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include responsive-below($grid-md){
      flex-direction: column;
    }
  }
}

.btn-margins {
  margin-right: 20px;
  margin-left: 13px;
}

.status {
  &-block {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &-bar {
    width: 100%;
    height: 2px;
    background: $icon-color;
    margin: 0 10px;
    position: relative;

    &-progress {
      position: absolute;
      left: 0;
      height: 4px;
      top: -1px;
      background: $status;
    }
  }

  &-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $icon-color;
    position: absolute;
    top: -3px;
    left: 0;

    &.active-true {
      background: $status;
    }

    &:nth-child(2){
      left: calc(33% - 4px);
    }

    &:nth-child(3){
      left: calc(66% - 4px);
    }

    &:nth-child(4){
      left: calc(100% - 8px);
    }
  }

  &-date {
    color: $icon-color;
  }
}


.label {
  display: block;
  color: $icon-color;
  font-size: 12px;

  &.-big {
    font-size: 14px;
  }

  &.-inline {
    display: inline-block;
  }
}

.value {
  color: $dark;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.8;

  &.-small {
    font-size: 12px;
  }

  &.-big-mobile {
    @include responsive-below($grid-md){
      font-size: 18px;
    }
  }

  &.-danger {
    color: $danger;
  }
}

.order-debt {
  align-items: center;

  .value + .value {
    margin-left: 20px;
  }
  @include responsive-below($grid-sm){
    justify-content: space-between;
  }
}

@include responsive-below($grid-sm){
  .btn-wrap-modal {
    flex-wrap: wrap;
    justify-content: center;

    .btn + .btn {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}


@include responsive-below($grid-md){
  .orders-tabs {
    padding-top: 20px;
  }
}
