@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.header {
  border-bottom: solid 1px #F2F3F3;
  margin-bottom: 30px;

  @include responsive-below($grid-md){
    margin-bottom: 0;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include responsive-below($grid-md){
      flex-direction: column;
    }

    &-mobile {
      display: flex;
      justify-content: center;
      padding: 14px 40px;
      width: 100%;
      background: #fff;
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    flex: 0 0 50%;
    padding: 10px 15px;
    height: 100%;
    background: $success;

    @include responsive-below($grid-md){
      display: block;
      height: auto;
      margin: -15px -15px 0 -15px;
      padding: 5px 15px;
      position: fixed;
      top: 64px;
      z-index: 2;
      width: 100%;
    }

    &.-danger {
      background: #C01717;
    }

    &.-success {
      background: #168A67;
    }

    &-label {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &-login {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;

    @include responsive-below($grid-md){
      padding: 15px 18px 30px 18px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    &:hover {
      .header-login-dropdown {
        opacity: 1;
        visibility: visible;
      }
    }

    &-icon {
      color: $brand;
      margin-left: 5px;

      @include responsive-below($grid-md){
        margin-right: 5px;
        margin-left: 0;
      }
    }

    &-dropdown {
      position: absolute;
      z-index: 2;
      top: 90%;
      right: 0;
      opacity: 0;
      visibility: hidden;
      padding: 10px 0;
      border: 1px solid #F2F3F3;
      background: #fff;
      box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      transition: all 0.4s;

      @include responsive-below($grid-md){
        top: auto;
        bottom: 100%;
        right: auto;
      }
    }

    &-link {
      display: block;
      padding: 15px 35px 15px 15px;
      white-space: nowrap;
      text-decoration: none;
      color: $dark;
      transition: all 0.3s;

      &:hover {
        background: rgba($icon-color, 0.15);
      }

      &.-danger {
        color: $danger;
      }
    }
  }

  &-mobile-block {
    width: 100%;
    position: fixed;
    top: 0;
    background: #fff;
    left: 0;
    z-index: 10;
  }
}

.logo {
  padding: 10px 0;

  @include responsive-below($grid-md){
    display: none;
  }
}

@include responsive-below($grid-md){
  .header .aside {
    display: none;
  }

  .header .container {
    padding: 0;
  }

  .header-info-value,
  .header-info-label,
  .header-info-col {
    display: inline-block;
  }

  .header-info-value {
    font-size: 12px;
    margin-left: 5px;
  }
}

.header-info-col {
  text-align: right;

  &:first-child {
    text-align: left;

    @include responsive-below($grid-md){
      text-align: left;
    }
  }
}
