@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.login {
  &-block {
    padding: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include responsive-below($grid-md){
      padding: 60px 0;
    }

    &:before {
      content: "";
      display: block;
      position: fixed;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: radial-gradient(198.97% 116.52% at 42.5% 92.5%, #449F4F 0%, #4EBE60 100%);
    }

    .footer,
    .footer a {
      z-index: 1;
      color: #fff;
    }
  }

  &-phone {
    margin-bottom: 8px;
  }

  &-content {
    margin-top: 80px;
    background: #fff;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    max-width: 100%;
    width: 440px;
    padding: 50px 25px;

    @include responsive-below($grid-sm){
      min-height: 65vh;
      width: 100vw;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      padding-bottom: 130px;
    }
  }

  &-info {
    color: $icon-color;
    margin-top: 35px;

    @include responsive-below($grid-sm){
      margin-top: auto;
      padding-top: 20px;
    }
  }

  &-confirm {
    margin-top: 35px;
    margin-bottom: 25px;
    color: $icon-color;
  }

  &-repeat {
    color: #1A5EC6;
    opacity: 0.3;
    font-weight: 500;
    cursor: default;

    &.active {
      opacity: 1;
      cursor: pointer;
    }
  }

  &-counter {
    font-size: 18px;
    line-height: 1.4;
    color: #000;
    margin: 8px;
  }
}
