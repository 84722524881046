@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.menu {
  background: $gray;
  border-radius: 8px;
  padding: 15px 0;

  @include responsive-below($grid-md){
    background: none;

    &-content {
      background: $gray;
      width: 100%;
      position: static;
      left: 0;
      transform: translateX(-110%);
      border-radius: 0;
      height: calc(100vh - 49px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.1s ease;
    }

    &-open .menu-content {
      transform: translateX(0);
    }
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;

    @include responsive-below($grid-md){
      padding: 16px 0;
    }
  }

  .badge {
    text-align: center;
    position: absolute;
    left: 43%;
    bottom: 50%;
    border-radius: 4px;
    background: red;
    margin-right: 3px;
  }

  &-item {
    display: block;

    & > span {
      display: inline-block;
      width: 20px;
      text-align: center;
    }
  }

  &-link {
    display: block;
    padding: 11px 15px;
    color: #000;
    text-decoration: none;
    transition: all 0.2s;

    @include responsive-below($grid-md){
      font-size: 18px;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    &:hover {
      background: rgba($icon-color, 0.15);
    }

    &.active {
      color: #fff;
      background: radial-gradient(198.97% 116.52% at 42.5% 92.5%, #449F4F 0%, #4EBE60 100%);

      .menu-icon {
        color: #fff;
      }
    }
  }

  &-icon {
    color: $icon-color;
    margin-right: 10px;
    width: 20px;
    display: inline-block;
    text-align: center;

    @include responsive-below($grid-md){
      width: 30px;
    }
  }
}

.toggle-bar {
  @include responsive-below($grid-md){
    position: absolute;
    left: 15px;
    top: 15px;
  }
}

.aside-info {
  text-align: center;
  margin-top: 15px;

  @include responsive-below($grid-md){
    display: none;
  }

  &-label {
    font-size: 12px;
    color: rgba(0,0,0,0.5);
    margin-bottom: 5px;
  }
}

.aside-message {
  font-size: 12px;
  line-height: 1.4;
  color: $dark;
  border: 1px solid $gray;
  border-radius: 8px;
  margin-top: 24px;
  padding: 12px 15px 12px 50px;
  background: url('../../assets/img/aside-message.svg') no-repeat 15px center;
}
