@import "../../../assets/styles/variables";

.list-links {
  list-style: none;
  margin: 5px 0 25px;
  padding: 0;

  &-item {
    padding: 15px 0;
    border-bottom: solid 1px $gray;

    &:first-child {
      border-top: solid 1px $gray;
    }
  }
}
