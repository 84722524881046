.mess-container {
  padding: 16px;
}

.mess-title {
  font-weight: 500;
  padding-bottom: 12px;
  font-size: 16px;
}

.mess-title, .mess-description {
  text-indent: 16px;
}
