@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.notice {
  &-list {
    padding: 0;
    margin: 10px 0;

    @include responsive-below($grid-md){
      margin: 10px -15px;
    }

    @include responsive-below($grid-sm){
      margin: 0 -15px;
    }
  }

  &-item {
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid $gray;
    cursor: pointer;
    text-decoration: none;
    color: black;

    &:hover {
      background: #f2f3f3;
    }

    @include responsive-below($grid-md){
      flex-direction: column;
    }

    &.unread {
      background: rgba($gray, 0.5);
      position: relative;

      &:before {
        content: "";
        width: 4px;
        height: 80%;
        background: $brand;
        position: absolute;
        left: 0;
        top: 10%;
      }
    }

    .order {
      &-code {
        color: $link-phone-color;
        padding: 0;
        @include responsive-below($grid-md){
          font-size: 14px;
        }
      }

      &-done {
        color: $brand;
        font-weight: 600;
      }

      &-cancel {
        color: $danger;
        font-weight: 600;
      }

      &-status {
        color: $status;
        font-weight: 600;
        padding: 0;
      }
    }
  }

  &-date {
    margin-left: 15px;
    font-size: 12px;
    color: $icon-color;
    flex: 0 0 105px;
    white-space: nowrap;

    @include responsive-below($grid-md){
      margin-left: 0;
      margin-top: 8px;
      flex-basis: auto;
    }
  }

  &-cog {
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 10;
  }
}

@include responsive-below($grid-sm){
  .users-top.page-title {
    padding: 0;
  }
}
