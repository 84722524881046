@import "../../assets/styles/variables";

.loader-wrapper {
  padding: 50px;
  text-align: center;

  &.small {
    padding: 0;

    .loader {
      width: 30px;
      height: 30px;

      &:after {
        width: 25px;
        height: 25px;
        border-width: 3px;
      }
    }
  }
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $brand;
    border-color: $brand transparent $brand transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
