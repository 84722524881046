@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.account {
  &-content {
    margin: 20px -12px;

    @include responsive-below($grid-md){
      flex-wrap: wrap;
    }

    &.-no-side-margin {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &-col {
    flex: 0 0 50%;
    padding: 0 12px;

    @include responsive-below($grid-md){
      flex-basis: 100%;
      margin-bottom: 30px;
    }
  }

  &-col-40 {
    flex-basis: 40%;

    @include responsive-below($grid-md){
      flex-basis: 100%;
    }
  }

  &-col-60 {
    flex-basis: 60%;

    @include responsive-below($grid-md){
      flex-basis: 100%;
    }
  }

  &-company {
    &:not(:last-child):after{
      content: ", ";
    }
  }
}

.bar-code {
  margin-left: -10px;
}
